import Helmet from 'react-helmet';
import React from 'react';
import { useSelector } from 'react-redux';

// This is placed where we use the search overlay or something similar, NOT the search pege itself
// Ex imports in FrontPage views. No need to pass the application down here.
const SiteSearchStructuredData = () => {
    const application = useSelector(
        state => state.application,
        (prev, next) =>
            prev.application?.domain !== next.application?.domain ||
            prev.application?.protocol !== next.application?.protocol ||
            prev.application?.path !== next.application?.path
    );

    // Setup the url
    const protocol = application.protocol ? `${application.protocol}://` : '';
    const domain = application.domain || '';
    const localePath = application.path || '';
    const baseUrl = protocol + domain;
    const searchPath = application.config.options.specified_pages.search_results?.slug || null;

    const sitelinksSearchbox = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        'url': baseUrl,
        'potentialAction': {
            '@type': 'SearchAction',
            'target': `${baseUrl}${localePath}${searchPath}?s={search_term_string}`,
            'query-input': 'required name=search_term_string',
        },
    };

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(sitelinksSearchbox, null, 2)}</script>
        </Helmet>
    );
};

export default SiteSearchStructuredData;
