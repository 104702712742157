/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';
import { recursiveDeleteKeyByValue } from 'utils/object';
import { useSelector } from 'react-redux';

/**
 * OrganizationStructuredData for Sagaform
 * @version 1.2
 *
 * OrganizationStructuredData Init in RouteResolver
 * @param {object} companyData - Building the organization data into the productstructured. passed down from views/product
 * @param {string} domain - application.domain, current domain
 * @param {string} protocol - application.protocol string. always https
 */

const OrganizationStructuredData = ({ companyData }) => {
    const { protocol, domain } = useSelector(
        state => state.application,
        (prev, next) =>
            prev.application?.domain !== next.application?.domain ||
            prev.application?.protocol !== next.application?.protocol
    );

    // Setup urls
    const protocolPath = protocol ? `${protocol}://` : '';
    const baseUrl = `${protocolPath}${domain}` || '';

    const contactOptions = companyData.contact_options || null;

    // Get company data from cms.
    const socialMediaLinks = companyData.social_medias
        ? companyData.social_medias.map(media => media.social_media_url)
        : null;
    const address = {
        streetAddress: companyData.organization?.address?.street_address || null,
        addressLocality: companyData.organization?.address?.address_locality || null,
        postalCode: companyData.organization?.address?.postal_code || null,
        addressCountry: companyData.organization?.address?.address_country || null,
    };

    // Contact, we fallback on null, since structured schema will want null instead of nothing.
    const contactPoint = contactOptions
        ? contactOptions.map(item => ({
              '@type': 'ContactPoint',
              contactType: item?.contact_type || null,
              telephone: item?.telephone || null,
              faxNumber: item?.fax || null,
              email: item?.email || null,
          }))
        : null;

    // Data from cms specifying the company information
    const organization = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        email: companyData.organization?.email || null,
        legalName: companyData.organization?.company_legal_name || null,
        name: companyData.organization?.company_name || null,
        sameAs: socialMediaLinks,
        url: baseUrl,
        address,
        contactPoint,
        logo: companyData.organization?.logo?.url || null,
    };

    // Remove null values from organization and breadcrumbList
    recursiveDeleteKeyByValue(organization, null);

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(organization, null, 2)}</script>
        </Helmet>
    );
};

OrganizationStructuredData.propTypes = {
    companyData: PropTypes.object.isRequired,
};

export default OrganizationStructuredData;
